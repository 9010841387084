.Dialog{
    width: auto;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: auto;
    .TopButton{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;

        button{
            background-color: #03235a;
            color: white;
            border-radius: 20px;
            padding: 5px 10px;
        }
    }
    .MiddlePart{
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        .Inputs{
            display: flex;
            flex-direction: column;
            label{
                color: gray;
                font-weight: 400;
            }
            input{
                border: 1px solid grey;
                border-radius: 5px;
                padding: 10px;
                color: black;
                font-weight: 300;
                width: 400px;
            }
            textarea{
                border: 1px solid grey;
                border-radius: 5px;
                padding: 10px;
                color: black;
                font-weight: 300;
                width: 400px;
            }
           
        }
    }
}