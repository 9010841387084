.Centre{

    .NotificationHead{
        
        h1{
            font-size: 25px;
        }
    } 
    .NotificationsCentre{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 50px;
        padding: 10px 20px;
    
        .Matters{
            width: fit-content;
            height: 100px;
            padding: 10px 20px;
            border-radius: 5px;
            box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.42);
            -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.42);
            background-color: #03a74d;

            .elements{
                display: flex;
                flex-direction: column;
                gap: 10px;

                span{
                    a{
                        text-decoration: none;
                        color:white;
                        font-weight: 400;
                        font-size: 20px;
                    }
                }

                .Counter{
                    span{
                        color: white;
                        font-weight: 300;
                        font-size: 20px;
                    }
                    button{
                        border: none;
                        outline: none;
                        background-color: #03a74d;
                        color: white;
                        border-radius: 5px;
                        padding: 5px 5px;
                        cursor: pointer;
                    }
                   
                }
            }
        }
    }
}

