/* LandingPage.css */

/* Styles for the suspended button */
.suspended-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.suspended-button button {
  background-color: #63a543;
  padding: 15px 15px;
  border: none;
  text-decoration: none;
  border-radius: 50%;
  font-weight: bold;
  transition: transform 0.2s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.suspended-button button .iconSuspence {
  color: white;
  font-size: 30px;
}

.suspended-button button:hover {
  transform: translateY(-5px);
  cursor: pointer;
}


.suspended-message {
  position: fixed;
  bottom: 100px; 
  right: 20px;
  z-index: 1000;
}

.message-box {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  display: flex;
  position: relative;
}

.message-box p {
  margin: 0;
  margin-top: 15px;
}

.message-box button {
  background-color: transparent;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  color: grey;
  font-size: 20px;
  cursor: pointer;
  bottom: 15px;
  position: absolute; /* Position the button absolutely within the message-box */
  top: -20px; /* Position the button at the top of the message-box */
  right: 0;
  font-weight: 500;
}


