.search{
    display: flex;
    align-items: center;
    border: 0.5px solid lightgray;
    padding: 3px;
}

.searchCases{
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    padding: 3px;
    margin-top: 0px;
}

.wording{
    padding: 0px;
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 0px;
}

.serachInput{
    background: white;
    width: 200px;
    color: black;
    border: 0.5px solid lightgray;
    border-radius: 5px;
    height: 35px;
    margin-top: 10px;
    padding: 10px;
}