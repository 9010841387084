.table {
    table-layout: fixed;
    text-align: center;
    width: auto;
    font-family: sans-serif; 
 
    .rows, .head {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        padding: 8px;
        font-family: sans-serif;
        
      }

      .TableFooter{
        background-color: transparent;
        
        .tableRow{
          width: 50px;
        }
      }

    .cellWrapper {
      display: flex;
      align-items: center;
  
    

      .image {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
      }
    }
  
    .status2 {
      padding: 5px;
      border-radius: 5px;
  
      &.Active {
        color: white;
        background-color: rgb(2, 177, 89);
      }
      &.Closed {
        color: goldenrod;
        background-color: rgba(189, 189, 3, 0.322);
      }
      &.Archived {
        color: rgb(207, 70, 36);
        background-color: rgba(175, 49, 17, 0.24);
      }

      &.Declined {
        color: rgb(219, 25, 18);
        background-color: rgba(187, 49, 15, 0.267);
      }
      &.Paid {
        color: green;
        background-color: rgba(0, 128, 0, 0.233);
      }
      &.Completed {
        color: green;
        background-color: rgba(0, 128, 0, 0.233);
      }
      &.Pending {
        color: rgb(219, 25, 18);
        background-color: rgba(187, 49, 15, 0.267);
      }
      &.Recalled {
        color: rgb(0, 31, 204);
        background-color: rgba(15, 41, 187, 0.267);
      }
      &.Accepted {
        color: green;
        background-color: rgba(0, 128, 0, 0.233);
      }
      &.Denied {
        color: rgb(219, 25, 18);
        background-color: rgba(187, 49, 15, 0.267);
      }

      &.Settled {
        color: rgb(0, 31, 204);
        background-color: rgba(15, 41, 187, 0.267);
      }
      &.Terminated {
        color: rgb(219, 25, 18);
        background-color: rgba(187, 49, 15, 0.267);
      }
     
    }
  }