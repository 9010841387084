.Auth{
    font-family: Open Sans,Roboto,Oxygen,Ubuntu,Cantarell,Lato,Helvetica Neue,sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
    top: 0;
    background-image: url(../LandingPage/Images/Legal.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 80vh;

    .background-overlay {

        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(1, 1, 61, 0.781); 

    .LoginPage{
        height: 70vh;
        display: flex;
        justify-content: center;
        background-color: #fff;
        border-radius: 0.25rem;
        box-shadow: 0 2px 6px #0000004d;
        max-width: 60rem;
        padding: 2.5rem 3rem;
        margin: 5rem auto 0;

        .Form{
            display: flex;
            flex-direction: column;
            gap: 2rem;
            width: auto;
            flex: 50%;

            .businessName{
                
            @media(max-width:767px){
                display: flex;
                justify-content: center;
                
            }
                span{
                  display: flex;
                  color: rgba(1, 1, 61, 0.781);
                  font-size: 2rem;
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                  font-weight: bold;

                  strong{
                    font-size: 2rem;
                    color: #ff6500;
                  }
                }
              }

              .Entry{
                display: flex;
                flex-direction: column;
                gap: 10px;
    
                label{
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    span{
                        color: red;
                        font-weight: bold;
                    }
                }
                input{
                    display: inline-block;
                    width: 300px;
                    min-height: 3rem;
                    border: 1px solid rgba(0, 0, 0, 0.692);
                    border-radius: 0.15rem;
                    outline-color: rgba(11, 118, 219, 0.651);
                    padding: 0.25rem 0.65rem;
                    color: #2c3e50;
                    margin: 0;
                    outline: 0;
                    opacity: 1;
                    z-index: 1;
                    transition: all .3s,height 0s;
                }
    
                input:hover{
                    border: 1px solid rgba(11, 118, 219, 0.651);
                }

                .PasswordShow{
                    display: flex;
                    flex-direction: row;
                    width: auto;
                    border: 1px solid rgba(0, 0, 0, 0.692);
                    outline-color: rgba(11, 118, 219, 0.651);
                    border-radius: 0.15rem;
                    outline: 0;
                    opacity: 1;
                    z-index: 1;
                    transition: all .3s,height 0s;

                    input{
                        width: 100%;
                        min-height: 3rem;
                        border: none;
                        padding: 0.25rem 0.65rem;
                        color: #2c3e50;
                        margin: 0;
                       
                    }
        
                  
    
                    button{
                        background-color: rgba(1, 1, 61, 0.781);
                        padding: 0.25rem 0.85rem;
                        color: white;
                        border: none;
                        min-height: 2rem;
                    }
                }
                .PasswordShow:hover{
                    border: 1px solid rgba(11, 118, 219, 0.651);
                }
    
            }

            .SubmitButton{
                display: flex;
                justify-content: center;
    
                button{
                    color: white;
                    background-color: rgba(1, 1, 61, 0.781);
                    border-radius: 5px;
                    border: none;
                    outline: none;
                    vertical-align: middle;
                    font-size: 1rem;
                    line-height: 1;
                    min-height: 2rem;
                    padding-left: 50px;
                    padding-right: 50px;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    cursor: pointer;
                }

                button:hover{
                    background-color: rgba(5, 5, 43, 0.781);
                    transition: 1s;
                }
            }

        }

        .LoginImage{
            flex: 50%;
            
        @media(max-width:767px){
          display: none;
            
        }

            img{
                width: 25rem;
                height: 20rem;
            }
        }
    }
}
}