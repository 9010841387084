.Tabs{
  padding: 20px 20px;
  width: fit-content;

  h2{
    text-align: center;
    color: #02b159;
    font-weight: bold;
  }
  
}