.MainStations{
   
    .CourtStation{
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
        margin-top: 20px;
    
        .Selections{
            label{
                font-weight: bold;
                font-size: 15px;
            }
    
            select{
                width: 300px;
                text-transform: uppercase;
                
                option{
                    text-transform: uppercase;
                }
            }
        }
    
    
    }

    .Popoups{
        margin-top: 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        margin-bottom: 20px;

        .PopoupsA{
           
            label{
                font-weight: bold;
                font-size: 15px;
            }

            select{
                width: 400px;
            }
        }
        
    }
}
