.LandingHome{
    padding-top: 20px;
    h2{
        
        font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
        font-weight: 600;
        font-size: 2vw;
        line-height: 1.5em;
        text-align: center;
        text-shadow: 0em 0.3em 1.4em rgba(0,0,0,0.24);

        strong{
            color: #ff6500;
            font-family: 'Poppins',Helvetica,Arial,Lucida,sans-serif;
            font-weight: 600;
            font-size: 2vw;
            line-height: 1.5em;
            text-align: center;
            text-shadow: 0em 0.3em 1.4em rgba(0,0,0,0.24);
        }
    }
    .imageScroll{
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 2rem;
        padding-top: 20px;
        padding-bottom: 50px;
        cursor: pointer;
        position: relative;
        transition: transform 0.5s ease-in-out;
        transform: translateX(0px);
        @media (max-width: 767px) {
            
            overflow: scroll;
          }

        .ImageDesc{
            border: 1px solid #ff6500;
            display: flex;
            flex-direction: column;
            border-radius: 2px;

            img{
                width: 230px;
                height: 160px;
                border-radius: 0px;
                left: 8px;
                top: 30px;
                z-index: 99; 
            }

            p{
                color: black;
                font-weight: bold;
                text-align: center;
                padding-top: 5px;
            }
          
        }
        .ImageDesc:hover{
            transform: translateX(10px);
            transition: transform 0.5s ease-in-out;
        }

       
    }

    .WhyUs{
        display: flex;
        gap: 20px;
        padding-left: 100px;
        padding-right: 100px;
        width: auto;
        margin-bottom: 20px;
        @media (max-width: 767px) {
           display: flex;
           width: auto;
           padding-left: 20px;
           padding-right: 20px;
          }


          .PartA,.PartB{
            flex: 1;
          }
        .PartA{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .features{
                display: flex;
                gap: 10px;

                .Icon{
                    color: #ff6500;
                }

                .featuresA{
                    display: flex;
                    flex-direction: column;

                }
            }
        }

        .PartB{
            display: flex;
            flex: 1;
            flex-direction: column;
            gap: 10px;

            .features{
                display: flex;
                gap: 10px;

                .Icon{
                    color: #ff6500;
                }

                .featuresA{
                    display: flex;
                    flex-direction: column;

                }
            }
        }
    }

    .footerMain{
        padding-top: 20px;
        background-color: #dd5800;
        height: auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 100px;
        padding-right: 100px;
        padding-bottom: 50px;
        padding-top: 50px;

        @media (max-width: 767px) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
           }

        .FooterA{
            display: flex;
            flex-direction: column;
            @media (max-width: 767px) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
               }
            h3{
                color: white;
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            span{
                color: white;
                a{
                    color: white;
                    text-decoration: none;
                    font-weight: bold;
                }
                a:hover{
                    color: rgb(3, 3, 56);
                }
                
            }
        }
    }
}