.CaseDetails{
    display: flex;
    justify-content: space-between;

    .PartADetails{
        display: flex;
        flex-direction: column;
        flex: 50%;
        gap: 5px;

        tr{
            display: flex;
            align-items: center;
            gap: 2rem;

            td{
                color: white;
                background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
                width: 30%;
                padding: 10px 20px;
            }

            span{
                color: black;
                font-weight: 500;
                width: 70%;
            }
        }
    }


    .PartBDetails{
        display: flex;
        flex-direction: column;
        flex: 50%;
        gap: 5px;
        padding-left: 25px;

        tr{
            display: flex;
            align-items: center;
            gap: 2rem;

            td{
                color: white;
                background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
                width: 40%;
                padding: 10px 20px;
            }

            span{
                color: black;
                font-weight: 500;
                width: 60%;
            }
        }
    }
}