.communications-container {
    display: flex;
    height: 85vh;
    max-width: 600px;
    margin: 0 auto;
    flex-direction: column;
    margin-top: 70px;
    background-color: #f3fafd;
    border-radius: 5px;
    border: 1px solid grey;
    overflow: scroll;
    @media (max-width: 767px) {
       padding-left: 4%;
       padding-right: 4%;
       border: none;
    }
  }
  
  .message-list {
    border-right: 1px solid #ccc;
    overflow-y: auto;
  }
  
  .conversation {
    display: flex;
    padding: 10px;
    cursor: pointer;
  
    &:hover {
      background-color: #e2dfdf;
    }
  
    .profile-picture {
      margin-right: 10px;
    }
  
    .message-preview {
      flex: 1;
    }
  
    .timestamp {
      font-size: 12px;
    }
  }
  
  .message-thread {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
  }
  
  .message {
    display: flex;
    margin-bottom: 10px;
  
    .profile-picture {
      margin-right: 10px;
    }
  
    .message-content {
      background-color: #e6f7ff;
      padding: 8px;
      border-radius: 5px;
      max-width: 70%;
    }
  
    .timestamp {
      font-size: 12px;
      margin-left: auto;
    }
  }
  
  .message-composer {
    display: flex;
    align-items: center;
    padding: 10px;
  
    input {
      flex: 1;
      padding: 8px;
      margin-right: 10px;
      border: 1px solid grey;
      outline: none;
      border-radius: 5px;
    }
  
  
  }
  