.ExpenseAccounts{
    display: flex;
    flex-direction: column;
    h3{
        text-align: center;
        font-weight: 400;
        font-size: 17px;
    }

    .TopPartExpenses{
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .itemExpense{
            display: flex;
            flex-direction: column;
            gap: 10px;
            padding: 10px 10px;
            background-color: #1e1e2c;
            color: white;
            border-radius: 5px;

            h2{
                font-size: 17px;
                font-weight: 500;
            }
            .amountExpense{
                span{
                    color: rgb(0, 255, 179);
                    font-size: 15px;
                    font-weight: 400;
                }
            }
        }
    }
    .CashCreditDebit{
        padding-top: 25px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding-bottom: 15px;

        .Credit{
            button{
                background-color: #063992;
                color: white;
                padding: 10px 10px;
                border-radius: 10px;
            }
        }
        .Debit{
            button{
                background-color: orange;
                color: white;
                padding: 10px 10px;
                border-radius: 10px;
            }
        }
    }
    .MiddleSection{
        padding-top: 10px;
    }
}