.btnone{
    border: none;
    color: white;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
    font-size: 10px;
    margin: 4px 2px;
    cursor: pointer;
}
.btn-edit{
    background-color: #241842;
}
.btn-view{
    background-color: #02b159;
    color: white;
}
.btn-delete{
    background-color: rgb(252, 15, 74);
}