.circle{
    padding: 10px 20px;
    margin-left: 8%;
    border-radius: 5px;
    font-size: 20px;
    color: white;
    background-color: #1F9D55;
    margin-bottom: 15px;
    border: none;
    cursor: pointer;

    @media(max-width: 767px){
        margin-left: 0%;
    }
}



.circle:hover{
    background-color: #3949ab;
    transition: 2s;
}
