
   .container {
    display: flex;
    flex-direction: column;
    position: relative;
    
    height: auto;
    background: #eeebeb;
    margin: 10px auto 0;
    word-break: break-all;
    width: auto;
  }
  
  .bloc-tabs {
    display: flex;
    border-left: 1px solid black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
  }
  .tabs {
  
    padding: 15px;
    text-align: center;
    width: 50%;
    background: white;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.274);
    box-sizing: content-box;
    position: relative;
    outline: none;
  }
  .tabs:not(:last-child){
    border-right: 1px solid rgba(0, 0, 0, 0.274);
  }
  
  .active-tabs  {
    background: white;
    border-bottom: 1px solid transparent;
  }
  
  .active-tabs::before {
    content: "";
    display: block;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 2px);
    height: 5px;
    background: #241842;
  }
  
  
  
  button {
    border: none;
    
  }
  .content-tabs {
    flex-grow : 1;
  }
  .content {
    background: white;
    padding: 20px;
    width: 100%;
    height: 100%;
    display: none;
  }
  .content h2 {
    padding: 0px 0 5px 0px;
  }
  .content hr {
    width: 100px;
    height: 2px;
    background: #222;
    margin-bottom: 5px;
  }
  .content p {
    width: 100%;
    height: 100%;
  }
  .active-content {
    display: block;
  } 
  .splitscreen {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .tdcont{
    font-family: Georgia, Times, "Times New Roman", serif;
    font-size: 14px;
    width: 50%;
    border-radius: 0px;
    font-weight: normal;
    height: 50px;
    background-color: #02b159;
    color: white;
    padding: 10px 20px;
    
  }
.splitscreen .leftsplit,
.splitscreen .rightsplit {
 flex: 1;
 justify-content: center;
 gap: 10px;
}

.card {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-style: normal; 
  font-variant: normal;
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  text-align: center;
  font-family: arial;
  border: 1px solid transparent;
}
.img{
  background-color: lightseagreen;
  color: white;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  font-size: 20px;
  align-items: center;
  justify-content: center;
}

.splitscreen2 .left2{
  flex: 0;
  float: left;
  padding-bottom: 15px;
  padding-left: 5%;
  text-align: left;
  padding-top: 3%;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 20px; 
  font-style: normal; 
  font-variant: normal;

}
.splitscreen2 .right2 {
  flex: 0;
  padding-top: 5%;
  padding-right: 5%;
  float: right;
  text-align: left;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 20px; 
  font-style: normal; 
  font-variant: normal;

}

.agree{
  
  padding-left: 5%;
  padding-top: 15px;
  padding-right: 5%;
  text-align: left;
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 18px; 
  font-style: normal; 
  font-variant: normal;
}

.btnPrint{
  background-color: rgb(61, 61, 66);
  color: white;
  width: auto;
  border: 1px solid black;
  border-radius: 5px;
 
}

.activitysearch{
  background: whitesmoke;
  border: 1px solid grey;
  color: black;
  border-radius: 5px;
  font-size: large;

}

.editIcon{
  background: teal;
  color: white;
  border-radius: 5px;
}