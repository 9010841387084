.InvoiceShow{
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    padding: 20px 25px;
    width: 100%;
    max-width: 800px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
 
    @media (max-width: 767px) {
        width: 100%;
     }

     .DialogContent{
        width: 500px;

        @media (max-width: 767px) {
            width: auto;
         }

        .ContextText{
            display: flex;
            flex-direction: column;
            gap: 10px;
     

    .InvoiceTitle{
        display: flex;
        justify-content: space-between;
        h3{
            color: black;
            font-weight: bold;
            font-size: 18px;
        }
    }
    .InvoiceCode{
        display: flex;
        justify-content: center;
        align-items: center;

        h5{
            font-size: 14px;
            font-weight: bold;
            color: black;
        }
    }
    .InvoiceSubTitle{
        display: flex;
        justify-content: space-between;

        .subTitle{
            display: flex;
            flex-direction: column;

            h4{
                font-weight: bold;
                font-size: 14px;
                color: #1F9D55;
            }
            span{
                color: black;
            }
        }
    }

    .NameSender{
        display: flex;
        justify-content: space-between;
        .SendReceive{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            h5{
                text-transform: capitalize;
                font-weight: bold;
                color: #1F9D55;
                font-size: 15px;
            } 

            span{
                color: black;
                font-weight: normal;
                font-size: 14px;
            }
        }
      
    }
    .Purpose{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-top: 10px;
        .SenderDetails{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            h5{
                text-transform: capitalize;
                font-weight: bold;
                color: #1F9D55;
                font-size: 15px;
            } 

            span{
                color: black;
                font-weight: normal;
                font-size: 14px;
            }
        }
    }

    .Summary{
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        .SummaryHead{
            display: flex;
            justify-content: center;
            align-items: center;

            h4{
                text-transform: capitalize;
                font-weight: bold;
                color: #1F9D55;
                font-size: 18px;
            }
        }
    }
    .SummaryBody{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;

        .summaryview{
            display: flex;
            justify-content: space-between;

            .description{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 5px;
                span{
                    font-weight: bold;
                    color: black;
                }
                small{
                    color: black;
                }
            }

            .amount{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                gap: 5px;

                span{
                    font-weight: bold;
                    color: black;
                }
                small{
                    color: black;
                }
            }
        }
        }
    .InvoiceFooter{
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        bottom: 0;

        span{
            color: black;
            font-weight: bold;
            display: flex;
            gap: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            small{
                color: blue;
                font-weight: bold;
            }
            small{
                color: rgb(216, 140, 0);
                text-decoration: dashed;
            }
        }
    }
}
}
}