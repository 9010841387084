.Price{

    .PriceMain{
        display: flex;
        flex-direction: column;
        gap: 30px;
        .PriceHead{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 50px;
        gap: 40px;
        h1{
            color: rgb(0, 0, 0);
            text-align: center;
            font-weight: 600;
            font-size: 55px;
        }
        span{
            font-weight: normal;
            font-size: 20px;
        }
    }
    .PriceBody{
            margin-top: 20px;
            display: flex;
            gap: 25px;
            align-items: center;
            justify-content: center;

            .editions{
                display: flex;
                flex-direction: column;
                border: 1px solid grey;
                border-radius: 5px;
                width: 400px;

                .editionshead{

                    height: 15vh; 
                    h3{
                        padding: 5px 10px;
                        color: #04304c;
                    }
    
                    p{
                       
                        width: auto;
                        padding: 5px 10px;
                    }
                    
                }

             

                hr{
                    color: black;
                    height: 1px;
                }

                .Outline{
                    display: flex;
                    align-items: center;
                    width: fit-content;
                    background-color: #2779ac41;
                    border: none;
                    border-radius: 5px;
                   
                    margin-left: 10px;
                    margin-bottom: 20px;
                    h2{
                        color: #04304c;
                        padding: 10px;
                        font-weight: bolder;
                        font-size: 40px;
                      
                    }
                    h4{
                        color: #04304c;
                        font-weight: normal;
                        font-size: 17px;
                        width: auto;
                        padding-right: 10px;
                    }
                }
               
            }
            .editions:hover{
                box-shadow: 2px 4px 7px 3px rgba(0,0,0,0.75);
                -webkit-box-shadow: 2px 4px 7px 3px rgba(0,0,0,0.75);
                -moz-box-shadow: 2px 4px 7px 3px rgba(0,0,0,0.75);
                transition: 1s;
            }
    }
    }

}