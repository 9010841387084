.Bot{
 
.BotPage{
    margin-top: 50px;
    background-color: #02b159;
    background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #02b159, #02b159);
    padding-bottom: 20px;
    padding-top: 10px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;;
    flex-direction: column;
    gap: 15px;

    .BotA{
            display: flex;
            flex-direction: row;
            gap: 50px;

        .ABot{
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 50%;

                h2{
                    color: white;
                }
                h5{
                    color: white;
                }
                .BotButton{
                    padding-top: 20px;
                    button{
                        color: white;
                        background-color: rgba(0, 0, 0, 0.877);
                        border: 1px solid black;
                        border-radius: 10px;
                        padding: 10px 20px;
                        font-weight: 600;
                    }
                }
             
        }

        .BBot{
            flex: 50%;
            padding-left: 100px;
            img{
                width: 100%;
                height: auto;
                border-radius: 10px;
            }
        }
   
 
}
   
}
.Overview{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    flex-direction: column;

    .Details{
        width: 800px;
        display: flex;
        flex-direction: column;
        span{
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}
   
}