.causelist{
    background: white;
    padding: 10px 20px;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 50px;
}
.causelistButton{
    background: #241842;
    border: 1px solid grey;
    color: white;
    border-radius: 5px;
    padding: 2px;
}
.causelistButton:hover{
    background: rgb(14, 26, 26);
}