.suggestionbox{
    padding: 10px;
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;

    .suggestionboxHead{
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: fit-content;
        justify-content: center;
        text-align: center;
        background-color: #6c5e9118;
        border: 1px solid transparent;
        border-radius: 5px;
        margin-left: 10%;
        margin-right: 10%;

        h5{
            color: #241842;
            font-weight: 700;
            font-size: 14px;
        }
        p{
            color: rgb(252, 15, 74);
            font-weight: bold;
        }
    }
    .suggestionForm{
        border: 1px solid transparent;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        background-color: #ada3c718;
        margin-left: 10px;
        margin-left: 10%;
        margin-right: 10%;

        input{
            background-color: #ada3c718;
        }

        label{
            font-weight: 700;
            color: #241842;
        }
        .buttonsuggestion{
            display: flex;
            justify-content: center;
            align-items: center;

            .suggestionButton{
                background-color: #24184200;
                color: black;
                border: 1px solid transparent;
                border-radius: 50%;
                padding: 5px 10px;
                justify-content: center;
                transform: rotate(-20deg);
            }
            .suggestionButton:hover{
                background-color: black;
                color: white;
            }
        }
       
    }
}