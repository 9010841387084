.Announcement{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .entries{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 5px;

        label{
            color: grey;
            font-weight: 400;
            font-size: 14px;
        }
        input{
            border: 1px solid grey;
            border-radius: 5px;
            padding: 5px;
            width: 300px;
        }
        textarea{
            border: 1px solid grey;
            border-radius: 5px;
            padding: 5px;
            width: 300px;
        }
    }

    .entries2{
        display: flex;
        flex-direction: column;
        gap: 10px;
        height: 20vh;
        overflow: scroll;

        label{
            color: grey;
            font-weight: 400;
            font-size: 14px;
            display: flex;
            gap: 20px;

            span{
                color: black;
            }
        }

       
    }

    button{
        margin-top: 10px;
        background-color: #03a74d;
        border-radius: 5px;
        padding: 10px 10px;
        color: white;
    }
}