.Usage{
   

    .Introduction{
        display: flex;
        flex-direction: column;
        color: white;
        padding-left: 30px;
        padding-right: 30px;
        background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #02b159, #02b159);

        span{
            color: white;
            font-size: 12px;
            padding-bottom: 20px;
        }

        h1{
            color: white;
            font-size: 25px;
            padding-bottom: 20px;
        }

        .buttons{
            display: flex;
            flex-direction: row;
            padding-bottom: 20px;
            gap: 1rem;

            button{
                color: white;
                background-color: rgba(0, 0, 0, 0.877);
                border-radius: 5px;
                padding: 10px 20px;
            }
        }
    }

    .GuideDetails{
        color: black;
        padding-top: 20px;
        padding-left: 30px;
        padding-right: 30px;

        h3{
            padding-bottom: 20px;
        }

        .Products{

            display: flex;
            justify-content: space-evenly;
            
        span{
            color: white;

            a{
                display: flex;
                flex-direction: column;
                color: black;
                text-decoration: none;
                font-size: large;
                font-weight: 500;

                .icon{
                    color: rgba(0, 0, 0, 0.877);
                    font-size: 50px;
                }
            }
        }

        span:hover{
            

            a{
                display: flex;
                flex-direction: column;
                color: black;
                text-decoration: none;
                font-size: large;
                font-weight: 500;

                .icon{
                    color: #02b159;
                    font-size: 50px;
                }
            }
        }


        }

    }
}