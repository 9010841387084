.StepThree{
    display: flex;
    flex-direction: column;
    gap: 20px;

    .Contacts{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;

        .Contact1{
            label{
                font-weight: bold;
                font-style: normal;
            }
            input{
                width: 400px;
            }
        }
    }

    .Summarys{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 25px;

        .summaryOne{
            margin-top: 25px;
            label{
                font-weight: bold;
            }
            input{
                width: 800px;
                margin-bottom: 25px;
            }

            
        }
    }
}