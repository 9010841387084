.navbarMain {
    height: 50px;
    display: flex;
    align-items: center;
    font-size: 14px;
    -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
    box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
    font-family: Georgia, Times, "Times New Roman", serif;
    font-style: normal; 
    font-variant: normal;
    background-color: #063992;
    width: auto;

    @media(max-width: 767px){
      width: auto;
    }
    
    .wrapper {
      width: 100%;
      padding: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .search {
        display: flex;
        align-items: center;
        border: 0.5px solid lightgray;
        padding: 3px;
  
        input {
          border: none;
          outline: none;
          background-color: white;
  
          &::placeholder {
            font-size: 12px;
          }
        }
      }
  
      .items {
        display: flex;
        gap: 30px;
        align-items: center;
        font-weight: bold;
  
        .item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          position: relative;
          font-family: Georgia, Times, "Times New Roman", serif;
          font-style: normal; 
          font-variant: normal;
  
          .icon {
            font-size: 20px;
            font-weight: bold;
            color: white;
          }
  
          .avatar {
            width: 30px;
            height: 30px;
            border-radius: 50%;
          }
  
          .counter {
            width: 15px;
            height: 15px;
            background-color: white;
            border-radius: 50%;
            color: orangered;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 10px;
            font-weight: bold;
            position: absolute;
            top: -5px;
            right: -5px;
          }

          .counter2 {
            width: auto;
            height: auto;
            border-radius: 5px;
            font-size: 10px;
            font-weight: bold;

         
          }
        }
      }
    }
  } 