.animated-text {
    position: relative;
    top: -100px;
    animation: moveFromAbove 1s forwards;
  
    h1 {
      font-size: 18px;
      color: #241842;
      text-transform: uppercase;
    }
  
    @keyframes moveFromAbove {
      0% {
        top: -100px;
        opacity: 0;
      }
      100% {
        top: 0;
        opacity: 1;
      }
    }
  }
  