.main{
    padding: 50px 50px;
} 

.file-card {
    display: flex;
    flex-direction: column;
    background-color: #edf2f7;
    min-width: 280px;
    min-height: 230px;
    padding-left: 100px;
    padding-right: 100px;
    height: auto;
    overflow: scroll;
  }

  .file-card label {
    align-content: center;
  }
  .editorContainer2{
    height: 150px;
    overflow: scroll;
    border:1px solid lightgrey;
  }
  .editor{
    height: 100%;
    border: none;
  }
  