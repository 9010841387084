.help{
    margin-top: 0px;
    height: 100vh;
}


.buttonlog{
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  color: #333;
  background-color: #fff;
  border-color: #ccc;
  font-weight: 700;
  text-transform: capitalize;
}

.help h1{
    font-size: 20px;
      
}

.partA{ 
    padding-left: 50px;
    background: white;
    width: 200px;
    height: 100px; 
}

.partB{
  background-color: #2f6f4e;
  background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
    color: white;
    width: 100%;
    display: flex;
    padding-left: 50px;
    padding-top: 50px;
    margin-top: 10px;
    gap: 2rem;

}
 
.column{ 
    color: white; 
    padding: 10px; 
    flex: 50%; 
    
  } 

  .column h6 a{
    color: white;
    text-decoration: none;
  }

  .services{
    margin-top: 10px;
    width: 100%;
    text-align: center;
    justify-content: center;
  }

  .pop-out-card {
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 300px;
    border: solid 1px #bbb;
    margin-left: 50px;
    margin-top: 20px;
    transition: all .2s;
    font: normal 16px calibri;
    margin-bottom: 20px;
  }
  .pop-out-card:hover {
    box-shadow: 2px 12px 15px #999;
  }
  .pop-out-card.with-transform:hover {
    transform: translateY(-4px);
  }

  @media screen and (max-width:769px){

    .column.one{

        display: none;
        
    }


    
}

  .pop-out-card2 {
    float: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 320px;
    height: 100px;
    border: solid 1px transparent;
    margin-left: 100px;
    margin-top: 20px;
    transition: all .2s;
    font: normal 16px calibri;
    margin-bottom: 60px;
  }
  .footer{
    background-color: #2f6f4e;
    background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    width: 100%;
    height: 150px
  }

  .footer button{
    color: white;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 5px;
    padding: 4px 20px;
  }

  .footer button a{
    color: white;
    text-decoration: none;
  }

  .footer span{
    color: white;
    font-weight: bold;
  }

  .footer small{
    color: white;
  }

  .footer small a{
    color: white;
    text-decoration: none;
  }