.sidebar {
  flex: 1;
  min-height: 100vh;
  -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.616);
  box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
  background-color: rgba(248, 241, 241, 0.76);
  font-family: Georgia, Times, "Times New Roman", serif;
  font-style: normal; 
  font-variant: normal;
 

  .top {
    padding-top: 20px;
    padding-left: 30px;
    width: auto;

    button{
      background-color: #02b159;
      background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
      color: white;
      padding: 5px 10px;
      border-radius: 10px;
      font-weight: 500;
      font-style: normal;
      
    }
  }

  hr {
    height: 0;
    border: 0.5px solid rgb(230, 227, 227);
  }

  .center {
    margin-top: 30px;
    padding-left: 30px;
    

    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .title {
        font-size: 10px;
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: 5px;
      }

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        cursor: pointer;

      

        .icon {
          font-size: 18px;
          color: black;
        }

        span{
            font-size: 13px;
            font-weight: 500;
            color: black;
            margin-left: 10px;
            font-family: Georgia, Times, "Times New Roman", serif;
            font-style: normal; 
            font-variant: normal;

            &:hover {
              color: black;
            }
        }

      }
    }
  }

  .bottom{
      display: flex;
      align-items: center;
      margin: 10px;

      .colorOption{
          width: 20px;
          height: 20px;
          border-radius: 5px;
          border: 1px solid #7451f8;
          cursor: pointer;
          margin: 5px;

          &:nth-child(1){
              background-color: whitesmoke;
          }
          &:nth-child(2){
              background-color: #333;
          }
          &:nth-child(3){
              background-color: darkblue;
          }
      }
  }
}