.RequisitionTwo{
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 20px;
    .TopSection{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 10px;

        .InputSection{

            input{
                border: none;
                outline: none;
                background: rgba(194, 187, 187, 0.404);
                border-radius: 10px;
                padding: 10px 15px;
                font-weight: 500;
            }
        }
      
        .FormSection{
            button{
                background-color: #02b159;
                padding: 5px 30px;
                border: none;
                outline: none;
                border-radius: 5px;
                color: white;
                font-weight: 500;
            }

            form{
                border: none;
                background-color: #02b159;
            }
            
        }
       

    }

   

    .Table{

        table {
            border-collapse: collapse;
            width: 100%;
         
          
          th, td {
            text-align: left;
            padding: 8px;
            border-bottom: 1px solid #ddd;
          }
          
          th {
            background-color: #f2f2f2;
            color: #333;
            font-weight: bold;
          }
          
          tr:nth-child(even) {
            background-color: #f9f9f9;
          }
    }
    }
}