.dark {
    background-color: black;
    color: rgb(65, 61, 61);

    *{
      background-color: black;
      color: white;

      p{
        color: white;
      }
      h1{
        color: white;
      }
      h2{
        color: white;
      }
      h3{
        color: white;
      }
      h4{
        color: white;
      }
      h5{
        color: white;
      }
      h6{
        color: white;
      }
      span{
        color: white;
      }

     button{
      background-color: rgba(255, 115, 0, 0.473);
     }
     label{
      color: white;
     }
    }
  
    .chartGrid {
      stroke: rgba(228, 228, 228, 0.219);
    }

    .Total{
        background-color: #111;
    }
  
    .table {
      background-color: black;
      color: white;
  
      .tableCell {
        color: white;
      }
    }
  
    .navbar {
      background-color: black;
      color: #999;
      border-color: #333;
  
      .search {
        border-color: gray;
        background-color: #111;
      }
    }
  
    .sidebar {
      background-color: #111;
      border-color: #333;
  
      .top {
        .logo {
          color: #999;
        }
      }
      hr {
        border-color: #333;
      }
  
      ul {
        li {
          &:hover {
            background-color: black;
          }
  
          .icon {
            color: #999;
          }
        }
      }
    }
  
    .datatable {
      .datagrid {
        color: gray;
        border: none;
  
        .viewButton,
        .deleteButton,
        .cellWithStatus {
          color: gray;
          border: none;
        }
      }
    }
  
    input{
        background-color: transparent;
    }
  }
  