.docheader{
    text-align: center;
    border: 1px solid gray;
    border-radius: 5px;
    background-color: rgba(0, 128, 0, 0.233);
    color: black;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-style: normal; 
    font-variant: normal;

}
.buttonItems{
    display: flex;
    flex-direction: column;
    gap: 2px;

    .buttonDoc{
        color: white;
        background-color: #02b159;
        font-family: Georgia, Times, "Times New Roman", serif;
        padding: 5px;
        border: none;
        border-radius: 20px;
        cursor: pointer;
        width: 20%;    
        
    }
}

.buttonDoc:hover{
    color: white;
    background-color: rgba(0, 0, 0, 0.719);
    font-family: Georgia, Times, "Times New Roman", serif;
    font-style: normal; 
    font-variant: normal;
}

.drafts{
    padding-left: 5%;
    padding-right: 5%;
    font-family: Georgia, Times, "Times New Roman", serif;
    font-style: normal; 
    font-variant: normal;
    @media print {
        .drafts {
          width: 100%;
          height: 100% !important;
          overflow: visible;
          font-family: Georgia, Times, "Times New Roman", serif;
          font-style: normal; 
          font-variant: normal;
        }
      }

    

    .demand{
        padding: 5px 0px;
        padding-top: 270px;
        padding-bottom: 30px;
        font-family: Georgia, Times, "Times New Roman", serif;
        font-style: normal; 
        font-variant: normal;

        .travel{
            display: flex;
            justify-content: space-between;
            margin-bottom: 5px;
            font-family: Georgia, Times, "Times New Roman", serif;
            font-style: normal; 
            font-variant: normal;

            h4{
                font-size: 18px;
                font-weight: 700;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-style: normal; 
                font-variant: normal;
            }
            
        }

        .body{
        
            text-align: left;
            font-family: Georgia, Times, "Times New Roman", serif;
            font-style: normal; 
            font-variant: normal;


            span{
                font-size: 16px;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-style: normal; 
                font-variant: normal;
            }
        }

      }

    .statutory{
        padding: 5px 20px;
        margin-top: 50px; 
        padding-bottom: 30px;
        font-family: Georgia, Times, "Times New Roman", serif;
        font-style: normal; 
        font-variant: normal;
       

        .stathead{
            text-align: center;
            text-decoration: underline;

            h4{
                font-weight: bold;  
                text-transform: uppercase;
                font-size: 16px;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-style: normal; 
                font-variant: normal; 
            }
           
        }
        .clientssection{
            padding-top: 15px;
            text-align: center;

            h5 h6{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px;
                font-style: normal; 
                font-variant: normal;
            }
          
        }


        .noticehead{
            text-align: center;

            h5{
                font-weight: 500;
                text-decoration: underline;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-style: normal; 
                font-variant: normal;
            }
          
        }

        .bodyStat{
            span{
                font-weight: 500;
                font-size: 15px;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-style: normal; 
                font-variant: normal;
            }
        }

        .firmdetails{
            display: flex;
            flex-direction: column;
            text-align: right;
            padding-top: 15px;
    
            img{
                float: right;
                width: 150px;
                height: 40px;
                
            }
           
            span{
                font-weight: bold;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 16px; 
                font-style: normal; 
                font-variant: normal;
               
            }
        }

        .drawnby{
            display: flex;
            flex-direction: column;
            padding-top: 20px;
    
            img{
                width: 150px;
                height: 40px;
            }
           
            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 16px; 
                font-style: normal; 
                font-variant: normal;
            }
        }
    }
    .plaint{
        padding: 5px 20px;
        padding-top: 30px;
        padding-bottom: 30px;
        @media only print {
            .plaint {
              width: auto;
              height: auto;
              overflow: visible;
            }
         }
        
        .plainthead{

            h4{
                text-align: center;
                text-decoration: underline;
                font-size: 16px; 
                font-weight: bold;
                text-transform: uppercase;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-style: normal; 
                font-variant: normal;
            }
          
        }

        .plaintParties{
            padding-top: 10px;
            
            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                font-style: normal; 
                font-variant: normal;
            }
        }

        .bodyPlaint{
            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                font-style: normal; 
                font-variant: normal;
            }
            .paragraphs{
                margin-top: 10px;

               li{
                padding-left: 20px;
                padding-top: 10px;
               }
            }

            .perticulars{
               span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 16px; 
                font-style: normal; 
                font-variant: normal;
                font-weight: bold;
                text-decoration: underline;
               }

               li{
                padding-left: 20px;
                padding-top: 7px;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                font-style: normal; 
                font-variant: normal;
               }
            
            }

            .listparticulars{
                    
                li{
                padding-left: 20px;
                padding-top: 3px;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                font-style: normal; 
                font-variant: normal;
            }
           }
           .paragraphsB{
            li{
                padding-left: 20px;
                padding-top: 3px;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                font-style: normal; 
                font-variant: normal;
            }
           }

        }

        .PlaintDrawer{
            display: flex;
            flex-direction: column;
            align-items: end;
            align-content: end;
            align-self: flex-end;
            padding-top: 15px;

            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                text-transform: uppercase;
                font-style: normal; 
                font-variant: normal;
                font-weight: bold;
            }
        }

        .PlaintDrawnBy{
            display: flex;
            flex-direction: column;
            margin-top: 10%;

            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                text-transform: uppercase;
                font-style: normal; 
                font-variant: normal;
            }
        }

        .servedTo{
            padding-top: 15px;
            h3{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 16px; 
                text-transform: uppercase;
                text-decoration: underline;
                font-style: normal; 
                font-variant: normal;
                font-weight: bold;
            }

            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                text-transform: uppercase;
                font-style: normal; 
                font-variant: normal;
            }
        }

        .noticeTitle{
            text-align: center;
            padding-top: 15px;
            
            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 16px; 
                font-style: normal; 
                font-variant: normal;
                text-decoration: underline;
                font-weight: bold;
                text-transform: uppercase;
            }
        }
    }

  }

  .upload{

    h5{
        color: red;
    }

   

    .uploaddoc{
        border: 1px solid black;
        border-radius: 5px;
        color: red;
        font-weight: bolder;
        width: auto;
    }

    .docbutton{
        display: flex;
        justify-content: center;
    }

    .uploadButton{
        background: teal;
        border: 1px solid black;
        border-radius: 5px;
        margin-top: 10px;
        padding: 10px;
        color: white;
        font-family: Georgia, Times, "Times New Roman", serif;
        font-style: normal; 
        font-variant: normal;
        font-weight: bold;
    }
    .uploadButton:hover{
        background: black;
        color: white;
        font-family: Georgia, Times, "Times New Roman", serif;
        font-style: normal; 
        font-variant: normal;
        font-weight: bold;
    }
  }


  .documentView{
    margin-top: 10px;

    h6{
        color: black;
        font-weight: bolder;
        font-size: large;
    }

    .majorfilebox{

        background: rgb(211, 204, 204);
        height:35vh;
        width: 60vh;
        padding: 5px 10px;
        border: 1px solid black;
        border-radius: 5px;

    .filebox{
        background: #effcb2;
        height: 30vh;
        width: 40vh;
        border: 1px solid black;
        border-radius: 5px;
        box-shadow: 2px 8px 12px #999;
    }

    .filebox:hover{
        box-shadow: 5px 12px 15px #999;
    }
}
}
