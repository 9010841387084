.DialogFormDetails{
    background-color: white;
    display: flex;
    flex-direction: column;

    @media (max-width: 767px) {
        width: 100%;
     }

    @media (max-width: 767px) {
        width: auto;
     }

     .dialogContentText{
        display: flex;
        flex-direction: column;
        gap: 10px;

        .CheckMate{
            display: flex;
            flex-direction: column;
            gap: 5px;

            label{
                color: black;
            }
            input{
                border: 1px solid black;
                outline: none;
                padding: 5px 10px;
                border-radius: 5px;

            }
            button{
                width: fit-content;
            }
        }

        .AllInputs{
            display: flex;
            flex-direction: column;
            gap: 5px;

            label{
                color: black;
            }
            input{
                border: 1px solid black;
                outline: none;
                padding: 5px 10px;
                border-radius: 5px;

            }
            select{
                border: 1px solid black;
                outline: none;
                padding: 5px 10px;
                border-radius: 5px;

            }
        }
        .MinorDetails{
            display: flex;
            flex-direction: column;
            gap: 5px;

            
        .AllInputs{
            display: flex;
            flex-direction: column;
            gap: 5px;

            label{
                color: black;
            }
            input{
                border: 1px solid black;
                outline: none;
                padding: 5px 10px;
                border-radius: 5px;

            }

            select{
                border: 1px solid black;
                outline: none;
                padding: 5px 10px;
                border-radius: 5px;

            }
        }
        }
     }

}