.MainStations{
   background-color: transparent;
   padding: 10px 15px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   gap: 10px;

   @media(max-width: 767px){
    display: flex;
    flex-direction: column;
    justify-content: center;
    }

   .CourtRank{
        display: flex;
        gap: 10px;
        justify-content: space-evenly;
        align-items: center;

        @media(max-width: 767px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
        }

        .CourtRankselect{
            display: flex;
            flex-direction: column;
            gap: 10px;

            label{
                color: black;
            }

            select{
                border: 1px solid grey;
                width: 300px;
                padding: 5px 10px;
                border-radius: 5px;
            }
        }
   }

   .DocumentsProvided{
    display: flex;
    justify-content: space-evenly;
    margin-top: 20px;
    border-top: 3px solid #1F9D55;

    @media(max-width: 767px){
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .ListingHeader{
        padding-top: 10px;
        h6{
            color: black;
            font-weight: bold;
            font-size: 16px;
        }
    }
    .DocumentsTick{
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-top: 10px;

        .TickDoc{
            display: flex;
            gap: 10px;
        }
    }

   }
}
