.legal-bot-interface {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 20px;
    margin: 20px;
    width: 400px;
    height: auto;
  
    .waving-icon {
      animation: waving 2s ease-in-out infinite;
      margin-bottom: 20px;
      font-size: 50px;
      color: red;
    }
  
    @keyframes waving {
      0% {
        transform: rotate(0deg);
      }
      25% {
        transform: rotate(20deg);
      }
      50% {
        transform: rotate(0deg);
      }
      75% {
        transform: rotate(-20deg);
      }
      100% {
        transform: rotate(0deg);
      }
    }
  }
  