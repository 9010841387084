.UserSummary{
    padding-top: 50px;
    padding-left: 30px;
    padding-right: 30px;

    @media (max-width: 767px) {
        
        padding: 20px;
        gap: 5px;
        margin-top: 10px;
    }



}