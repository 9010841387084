.CaseView{
    padding-top: 80px;
    padding-right: 3%;
    padding-left: 3%;
    padding-bottom: 3%;

    @media(max-width:767px){
        
    }

    .IntroDetails{
        position: relative;
        padding: 20px 30px;
        margin-top: 5px;
        margin-left: 10px;
        margin-right: 10px;
        background: url('./lawfirm.png') no-repeat center center fixed;
        background-size: cover;
        color: white;
        display: flex;
        justify-content: space-evenly;
        
      
        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: linear-gradient(rgba(0, 0, 0, 0.671), rgba(0, 0, 0, 0.548));
        }

        .NameIntro{
            h4{
                filter: brightness(1.0);
            }
            span{
                filter: brightness(1.0);
                background-color: #02b159 !important;
                color: white;
                border: none;
                border-radius: 5px;
                padding: 0px 5px;
            }
            h5{
                filter: brightness(1.0);
            }
            p{
                filter: brightness(1.0);
            }
        }
    }

    .MinorDetails{
        .TabBar {
            margin-top: 25px;
            .TabButtons {
              display: flex;
              justify-content: space-evenly;
              margin-bottom: 20px;
        
              @media(max-width:767px){
                display: flex;
                justify-content: space-evenly;
                margin-bottom: 20px; 
            }
          
              button {
                padding: 10px 20px;
                background-color: transparent;
                border: none;
                cursor: pointer;
                font-size: 16px; 
                color: #333;
                transition: background-color 0.3s;
                border-bottom: 2px solid transparent;
          
                &.ActiveTab {
                  background-color: transparent;
                  color: black;
                  border-color: #063992;
                }
          
                &:not(:last-child) {
                  margin-right: 10px;
                }
              }
              button:hover{
                background-color: #6396f063;
              }
            }
            .TabContent {
                padding: 20px;
                background-color: #f5f5f5;
                border: none;

                .caseDetails{
                    padding-left: 50px;
                    padding-right: 50px;
                    .partOneCase{
                        h3{
                            text-align: left;
                            padding-right: 50px;
                            font-size: 20px;
                            color: black;
                            font-weight: 600;
                            width: fit-content;
                            background-color: #02b159 !important;
                            color: white;
                            padding: 10px;
                            border-radius: 5px;
                        }
                        .DetailView{
                            padding-top: 20px;
                            display: flex;
                            justify-content: space-around;
                            flex: 1;

                            .detailAlign{
                                flex: 1;
                            .viewA{
                                display: flex;
                                gap: 10px;
                                justify-content: flex-start;
                                align-items: flex-start;
                                font-family: Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica Neue,Arial,sans-serif;

                                span{
                                    font-weight: 500;
                                    color: black;
                                    font-size: 16px;
                                }
                                p{
                                    color: black;
                                    font-size: 15px;
                                }
                                button{
                                    background-color: #02b159 !important;
                                    color: white;
                                    border: none;
                                    outline: none;
                                    padding: 4px 5px;
                                    border-radius: 5px;

                                }
                                
                            }
                        }
                    }
                    }
                }
                .Messages{
                    width: 100%;
                    height: 100%;
                    h2{
                        font-size: 19px;
                        font-weight: 600;
                        text-align: center;
                    }
                    .messageCompose{
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding-top: 15px;
                        gap: 10px;
                        .MessageInputs{
                            display: flex;
                            flex-direction: column;

                            label{
                                color: rgb(34, 33, 33);
                                font-weight: 400;
                                font-size: 15px;
                            }
                            input{
                                width: 400px;
                                padding: 5px 10px;
                                border-radius: 5px;
                                border: 1px solid grey;
                                outline-color: teal;
                            }

                            select{
                                width: 400px;
                                padding: 5px 10px;
                                border-radius: 5px;
                            }
                            textarea{
                                width: 400px;
                                padding: 5px 10px;
                                border-radius: 5px;
                            }
                            button{
                                background-color: #02b159 !important;
                                color: white;
                                padding: 5px 10px;
                                border: none;
                                outline: none;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }
    }

    
}