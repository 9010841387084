.customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    
  }
  
  .customers td, .customers th {
    border: 1px solid rgb(143, 128, 128);
    padding: 8px;
    font-weight:500;
  }
  
  .customers tr:nth-child(even){background-color: #f2f2f2;}
  
  .customers tr:hover {background-color: #ddd;}
  
  .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #cfb53b;
    color: black;
  }

  .documents{
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 40%;
   
  }

  .documents th {
    padding-top: 12px;
    border: 1px solid rgb(143, 128, 128);
    padding-bottom: 12px;
    text-align: left;
    background-color: #cfb53b;
    color: black;
    text-align: center;
  }
  .documents td {
    color: black;
    font-weight:500;
    border: 1px solid rgb(143, 128, 128);
  }

  .diarize{
    max-width: fit-content;
  }

