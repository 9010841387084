.TripSummary{
    padding-top: 100px;

    .ReportGeneration{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
        padding-bottom: 30px;
        width: 100%;

        @media (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);
            justify-content: center;
            align-items: center;
        }
        
    }
    .ReportGeneration .grid-item {
        display: flex;
        justify-content: center;
        align-items: center;
       
    }
}