.TabBar {
    margin-top: 25px;
    .TabButtons {
      display: flex;
      justify-content: space-evenly;
      margin-bottom: 20px;

      @media(max-width:767px){
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 20px;
    }
  
      button {
        padding: 10px 20px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        font-size: 16px; 
        color: #333;
        transition: background-color 0.3s;
        border-bottom: 2px solid transparent;
  
        &.ActiveTab {
          background-color: #6396f063;
          color: black;
          border-color: #063992;
          border-radius: 5px;
        }
  
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
      button:hover{
        background-color: #6396f063;
        border-radius: 5px;
      }
    }
  
    .TabContent {
      padding: 20px;
      background-color: #f5f5f5;
      border: none;

    }
  }
  