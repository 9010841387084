.home {
    display: flex;
    background-color: rgb(250, 244, 244);
    padding-left: 0px;
    padding-top: 0px;
    width: auto;
    
  
    .homeContainer {
      flex: 6;
   
      .widgets
       {
        display: flex;
        padding: 20px;
        gap: 20px; 
        padding-bottom: 15px;
        animation: moveRightToLeft 2s infinite alternate;

        &::before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: white;
        }
      
        @keyframes moveRightToLeft {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(0px); /* Adjust the distance as needed */
            }
          }
        
      }

      .MainBody{
        display: flex;
        margin-right: 30px;
        margin-left: 30px;
        flex-direction: 1;
        width: auto;

      @media(max-width: 767px){
        display: flex;
        flex-direction: column;
        width: auto;
      }

      }
  
      .charts {
        padding: 5px 20px;
        
      }
  
      .listContainer {
        -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
        box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
        padding: 20px;
        margin: 20px;
  
        .listTitle {
          font-weight: 500;
          color: gray;
          margin-bottom: 0px;
        }
      }
    }
  }