.lawfirmbody{
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: white;
    margin: 50px;
    border: 3px solid rgba(128, 128, 128, 0.253);
    padding-left: 50px;
    padding-right: 50px;
    color: rgb(14, 14, 78);
    gap: 15px;
    padding-bottom: 20px;

    h1{
        text-align: center;
        margin-bottom: 20px;
    }
    p{
        text-align: center;

        a{
            color: #02b159;
            text-decoration: none;
        }
        
        a:hover{
            color: #02b159;
            text-decoration: underline;
        }
    }
    .lawbutton{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        a{
         color: blue;
        text-decoration: none;  
        font-weight: 600;
        font-size: 17px;  
        }
        
    }

    .bodyRegistration{

        display: flex;
        

    .Another{
        display: flex;
        flex: 1;
        flex-direction: column;
        
         

        h4{
            text-align: center;
            margin-bottom: 50px;
        }

       .buttons{
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: center;
            align-items: center;

            button{
                background-color: white;
                border: 1px solid grey;
                border-radius: 5px;
                padding: 20px 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                font-weight: 600;
                font-size: 20px;

                .icon{
                    color: green;
                    font-weight: 600;
                    font-size: 20px; 
                }
            }
       }
    }

    .Border{
        display: flex;
        border-right: 1px solid green;
        margin-bottom: 20px;
    }

    form{
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 100%;
        border: none;
        

        .lawfirm{
            display: flex;
            flex-direction: column;

            label{
                color: rgb(14, 14, 78);
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 5px;
            }
            input{
                width: 300px;
                height: 5vh;
                padding: 20px;
                font-weight: 600;
            }
        }
        .buttonsLink{
            display: flex;
            flex-direction: column;
            align-items: center;

            h4{
                background-color: #02b159;
                border-radius: 5px;
                border: none;
                color: white;
                width: fit-content;
                padding: 10px 30px;
                font-size: 20px;
                font-weight: 600;
                margin-top: 10px;
                cursor: pointer;

            }
        }

    }
}
}