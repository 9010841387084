.HomeView {
    .HomePage {
      position: relative;
      min-height: 100vh;
      display: flex;

      @media(max-width: 767px){
       min-height: 130vh;
     }
  
      .background-overlay {
        position: absolute;
        display: flex;
        justify-content: space-evenly;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        @media(max-width: 767px){
            display: flex;
            flex-direction: column;
        }
  
        .HomePartA, .HomePartB {
          flex: 1;
        }
  
        .HomePartA {
            background-color: rgb(17, 43, 75);
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            padding-left: 30px;
            padding-right: 30px;
            padding-top: 10%;
            padding-bottom: 30px;
            gap: 25px;
            flex: 1;

            @media(max-width: 767px){
                background-color: rgb(17, 43, 75);
                height: 120vh;
                display: flex;
                padding-top: 10%;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                padding-left: 20px;
                gap: 25px;
                flex: 3;
            }
  
            h5{
              color: white;
             
            }
          h2 {
            color: white;
            @media(max-width: 767px){
            font-size: 35px;
            font-weight: 500;
           }
          }
          h3{
            color: white;
            font-size: 20px;
          }
  
          h1 {
            color: white;
            font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
            font-weight: 600;
            font-size: 4vw;
            line-height: 1.5em;
            text-align: center;
            text-shadow: 0em 0.3em 1.4em rgba(0, 0, 0, 0.24);
            @media(max-width: 767px){
               display: none;
            }
            strong {
              color: #ff6500;
              font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
              font-weight: 600;
              font-size: 4vw;
              line-height: 1.5em;
              text-align: center;
              text-shadow: 0em 0.3em 1.4em rgba(0, 0, 0, 0.24);
            }
          }
  
          p {
            color: white;
            text-align: center;
            width: 600px;
            font-family: 'Poppins', Helvetica, Arial, Lucida, sans-serif;
            line-height: 1.5em;
            text-shadow: 0em 0.3em 1.4em rgba(0, 0, 0, 0.24);
          }
  
          .Buttons {
            display: flex;
            justify-content: space-evenly;
            margin-top: 20px;
            gap: 20px;
          
            @media (max-width: 767px) {
              flex-direction: column;
              gap: 20px;
            }
          
            button {
              background-color: #63a543;
              padding: 10px 30px;
              border: none;
              border-radius: 5px;
              color: white;
              font-weight: bold;
              font-size: 17px;
          
              @media (max-width: 767px) {
                width: 100%; 
                font-weight: bold;
                font-size: 20px;
              }
            }
          
            .BtVideo {
              button {
                background-color: transparent;
                border: none;
                outline: none;
                color: white;
                font-weight: 500;
                font-size: 17px;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
          
                .iconplay {
                  background-color: rgb(80, 79, 79);
                  border: none;
                  border-radius: 50%;
                }
              }
            }
          }
          
        }
        .HomePartB {
         
          display: flex;
          flex: 1;
          background-color: rgb(17, 43, 75);
        
          .image-container {
            position: relative;
            max-width: 100%;
            height: 100%;
            display: block;
            margin: 0 auto;
         
        
            img {
              max-width: 100%;
              height: 100%;
              display: block;
              margin: 0 auto;
              filter: brightness(0.3);
            }
        
            .overlay-text {
              position: absolute;
              top: 50%; 
              left: 45%; 
              transform: translate(-50%, -50%);
              color: white;
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              align-items: flex-start;
              gap: 15px;

              @media(max-width: 767px){
              display: none;
             }

              .textIndividual{
                display: flex;
                gap: 10px;

                .icon{
                  color:  #63a543;
                  font-size: 25px;
                }

                .textSpan{
                  color:white;
                  font-size: 15px;
                  font-weight: 500;
                }
              }
            }
          }
        }
        
      }
    }
  }
  