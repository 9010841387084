.clients {
    table-layout: fixed;
    text-align: center;
    width: auto;
    font-family: sans-serif;
    padding: 0px;
    
  }
  
  .clients td, .clients th {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    padding: 8px;
    font-family: sans-serif;
  }


  .status2{
    margin-top: 7px;
    font-weight: bold;
    color: white;
    padding: 3px 5px;
    border-radius: 5px;
    text-align: center;
    
  }

  .input{
    border: 3px solid grey;
    border-radius: 5px;
    color: black;
    float: left;
    margin-top: 10px;
  }
  .Link{
    float: right;
  }
  .buttonlink{
    border: 1px solid grey;
    border-radius: 5px;
    background: var(--card-06-bg);
    color: white;
    cursor: pointer;
    text-decoration: none;
    padding: 8px;
  }
  .buttonlink:hover{
    background: var(--primary-color);
    color: white;
  }


