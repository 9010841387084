.UserCaseView{
    padding-top: 50px;
    margin-left: 5%; 
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: auto;
    margin-bottom: 50px;

    
   @media screen and (max-width: 767px) {
 
    margin-right: 2%;
    margin-left: 2%;
    padding-right: 5px;
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.StationSearch{
    input{
        border: 1px solid grey;
        outline: none;
        padding: 10px 15px;
        border-radius: 5px;
        color: black;
        text-transform: uppercase;
    }
}

}