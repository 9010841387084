.dropbutton {
    display: flex;
    align-items: center;
    gap: 5px;
    color: white;
    padding: 5px 5px;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }
   
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
   
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 1124px;
    box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 4px 6px 2px rgba(0,0,0,0.75);
    z-index: 2;
    height: auto;
  }

  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown:hover .dropdown-content {display: block;}

  .Community{
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    padding-top: 50px;
  }

  .CommunityA{
    padding: 10px 20px;
    background-color: #02b159;
    background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
    color: white;
    height: 30vh;
    display: flex;
    gap: 20px;
    
  }

  .CommunityB{
    display: flex;
    flex-direction: column;
    gap: 0px;
  }
.CommunityB h6{
  color: grey;
}

.CommunityB a{
  color: black;
  font-weight: 500;
}

.CommunityB a:hover{
  color: #0abb62;
}

.CommunityC{
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding-bottom: 30px;
}

.CommunityC a{
  color: black;
  font-weight: 500;
}

.CommunityC a:hover{
  color: #0abb62;
  font-weight: 500;
}

.CommunityC button a{
 color: white;
}

.ReportButton{
  background: red;
  border-radius: 5px;
  
}

.ReportButton:hover{
  background-color: rgba(255, 102, 0, 0.918);
  border-radius: 5px;
}


.ReportButton a{
  color: white;
  font-weight: 500;
  text-transform: uppercase;
}
.ReportButton a:hover{
  color: white;
  font-weight: 500;
}
