.uploadbutton{
    width:'150px';
    padding: 10px;
    border: 'none';
    background-color: #241842;
    color: white;
    font-weight: bold;
    cursor: pointer;
    margin-top: 10px;

    &:disabled{
        background-color: #5d596833;
        cursor: not-allowed;
    }
}