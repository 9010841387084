.Expenses{
    padding-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: auto;
    margin-bottom: 50px;

    @media screen and (max-width: 767px) {
        margin-top: 3%;
        margin-right: 3%;
        margin-left: 3%;
        padding-right: 5px;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    } 

    .topExpense{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}.Expenses{
    padding-top: 120px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    height: auto;
    margin-bottom: 50px;

    @media screen and (max-width: 767px) {
        margin-top: 3%;
        margin-right: 3%;
        margin-left: 3%;
        padding-right: 5px;
        padding-left: 5px;
        display: flex;
        flex-direction: column;
        gap: 15px;
    } 

    .topExpense{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
        }
    }
}