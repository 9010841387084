.Policy{

    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .headerPolicy{
        display: flex;
        flex-direction: column;
        align-items: center;
        background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
        height: 50vh;
        padding-top: 17vh;

        h1{
            color: white;
            font-weight: 700;
            font-size: 50px;
        }

        span{
            color: white;
            font-weight: 700;
            font-size: 20px;
        }
    }
}