.progress-bar-container {
    margin: 20px 0;
  }
  
  .progress-bar {
    width: 200px;
    height: auto;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    position: relative;
    border-radius: 5px;
  }

  
  .progress-bar-fill {
    background-color: rgb(235, 154, 4);
    height: 100%;
    text-align: center;
    line-height: 20px;
    color: #fff;
    padding: 10px 10px;
  }
  