.CashIn{
    display: flex;
    flex-direction: column;
    gap: 10px;

     .CreditTop{
        display: flex;
        gap: 35px;
        justify-content: center;
        align-items: center;
        align-content: center;

        .CrediEntry{
            display: flex;
            flex-direction: column;
            gap: 5px;
            
            label{
                color: grey;
            }
            select{
                border: none;
                outline: none;
                border-radius: 5px;
                background-color: rgb(231, 226, 226);
                padding: 10px 10px;
                width: 350px;

                option{
                    border: none;
                    outline: none;
                    background-color: white;
                    border-bottom: 1px solid grey;
                }
            }
            input{
                width: 200px;
                border: none;
                outline: none;
                border-radius: 5px;
                background-color: rgb(231, 226, 226);
                padding: 10px 10px;
            }
            button{
                border: none;
                outline: none;
                border-radius: 20px;
                background-color: rgb(171, 233, 171);
                padding: 10px 10px;
            }
        }
     }
     .CreditCenter{
        display: flex;
        flex-direction: column;

        label{
            color: grey;
        }
        input{
            width: 300px;
            border: none;
            outline: none;
            border-radius: 5px;
            background-color: rgb(231, 226, 226);
            padding: 10px 10px;
        }
     }
     .AddCategory{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .CatgoryAdd{
            display: flex;
            flex-direction: column;
            gap: 20px;
            label{
                color: grey;
            }
            input{
                width: 300px;
                border: none;
                outline: none;
                border-radius: 5px;
                background-color: rgb(231, 226, 226);
                padding: 10px 10px;
            }
        }
        
     }
}