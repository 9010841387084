.sidebarNavigation {
    flex: 1;
    min-height: 120vh;
    width: 200px;
    transition: width 0.3s ease;
    color:white; 
    background-color: #063992;

    @media(max-width: 767px){
      display: none;
    }
    &.closed {
      width: 0;
    }
  
    &.minimized {
      width: 60px;
  
      .sidebar-tab {
        span {
          display: none;
        }
      }
    }
  
    .toggle-button {
      display: none;
    }
  
    .sidebar-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 100%;
  
      .sidebar-header {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        padding: 0 10px;
       
  
        .logo {
          font-size: 24px;
          font-weight: bold;
  
          &.minimized {
            display: none;
          }
        }
      }
  
      .sidebar-tabs {
        flex-grow: 1;
        overflow-y: auto;
        padding: 10px 0;
        padding-top: 70px;

        .AvatarUser{
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .AvatarA{
            background-color: rgb(224, 219, 219);
            padding: 15px;
            border-radius: 50%;
            border: 2px solid white;
            cursor: pointer;

            .AvatarIcon{
              color: #1e1e2c;
              
              .IcontSelf{
                font-size: 40px;
              }
              
            }
          }
          .AvatarB{
            color: white;
            font-size: 17px;
            font-weight: 500;
            font-family: Georgia,Times,Times New Roman,serif;
          }

        }
  
        .sidebar-tab {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 12px;
          cursor: pointer;
          padding-top: 30px;
  
          svg {
            width: 24px;
            height: 24px;
            margin-right: 12px;
          }
          a{
            text-decoration: none;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
          span {
            font-size: 14px;
            font-weight: 500;

            button{
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                padding: 7px 15px;
                background-color: rgba(128, 0, 128, 0.39);
                color: white;
                outline: none;
                cursor: pointer;
                border-radius: 10px;
            }
          }
        }
        span {
            font-size: 14px;
            font-weight: 500;

            button{
                display: flex;
                justify-content: center;
                align-items: center;
                border: none;
                padding: 7px 15px;
                background-color: transparent;
                color: white;
                outline: none;
                cursor: pointer;
                border-radius: 10px;
            }
          }
  
          &:hover {
            background-color: transparent;
          }
  
          &.active {
            background-color: transparent;
            color: white;
          }
        }
      }
    }
  
    @media (max-width: 767px) {
      width: auto;
  
      .toggle-button {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        height: 60px;
        padding: 10px;
        background-color: transparent;
        cursor: pointer;
        border-bottom: 1px solid transparent;
  
        svg {
          width: 24px;
          height: 24px;
        }
      }
  
      .sidebar-content {
        display: flex;
      }
  
      &.minimized {
        .sidebar-content {
          display: none;
        }
  
        .sidebar-header {
          .logo {
            display: none;
          }
        }
  
        .sidebar-tab {
          span {
            display: inline;
          }
        }
      }
    }
  
    @media (min-width: 768px) {
      .toggle-button {
        display: flex;
        align-items: flex-start;
        padding: 10px;
        justify-content: flex-start;
        height: 60px; 
        background-color: transparent;
        cursor: pointer;
        border-bottom: 1px solid transparent;
  
        svg {
          width: 24px;
          height: 24px;
        }
      }
  
      .sidebar-content {
        display: flex;
      }
  
      &.minimized {
        width: 60px;
  
        .sidebar-header {
          .logo {
            display: none;
          }
        }
  
        .sidebar-tab {
          span {
            display: none;
          }
        }
      }
    }
  }
  