.SettingForm{
display: flex;
flex-direction: column;
background-color: transparent;
margin-top: 5%;
margin-bottom: 5%;
padding: 5% 5%;
margin-left: 5%;
margin-right: 5%;
box-shadow: 0  4px 8px rgba(0, 0, 0, 0.445); 


@media(max-width: 767px){
    margin-top: 17%;
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.ImageSettings{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5px;
    margin-bottom: 30px;
    background-color: rgba(90, 92, 92, 0.26);
    padding: 2% 2%;
    border-radius: 10px;


    @media(max-width: 767px){
        background-color: rgba(90, 92, 92, 0.26);
        padding: 2% 2%;
        margin-bottom: 5%;
    }

    img{
        height: 70px;
        width: 70px;
        border-radius: 50%;
        object-fit: cover;
        background-color: #1F9D55;
        cursor: pointer;
        position: relative;
    }
    img:hover{
        transition: 2s;
        background-color: #ddd;
        cursor: pointer;
        border: 2px solid grey;
        border-style: dotted;
    }

    .input-wrapper_image{
        width: 300px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 15px;
        padding-top: 10px;

        input[type='file'] {
            display: none;
        }
    
   
        .custom-file-input {
            display: inline-block;
            padding: 2px 2px;
            cursor: pointer;
            color: black;
            border: none;
            outline: none;
            border-radius: 50%;
            color: white;
            background-color: #02b159 !important;
        }

        button{
            background-color: red !important;
            color: white;
            border: none;
            outline: none;
            padding: 2px 2px;
            border-radius: 50%;
            cursor: pointer;
            
        }
        
    }
}

.SettingEntryView{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 50px;

    @media(max-width: 767px){
        display: flex;
        flex-direction: column;
        background-color: transparent;
        gap: 15px;
    }

.SettingsPartA{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

        .input-wrapper{
            position: relative;
            line-height: 14px;
            margin: 0 10px;
            display: inline-block;

            label{
                color: black;
                font-weight: bold;
                font-size: 12px;
                text-transform: uppercase;
                position: absolute;
                z-index: 2;
                left: 20px;
                top: 14px;
                padding: 0 10px;
                background-color: white;
                transition: transform .2s ease;
                border: none;
            }

            input{
                font-size: 14px;
                color: black;
                background-color: white;
                outline: none;
                border: 1px solid #4d4d4d;
                padding: 10px 20px;
                border-radius: 20px;
                position: relative;
                width: 300px;
            }

            input:focus + label,
            input:valid + label,
            &:hover input + label {
                color: #4d4d4d;
                transform: translateY(-20px);
                border: none;
            }
           
        }
    
}

.SettingsPartB{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .input-wrapper{
        position: relative;
        line-height: 14px;
        margin: 0 10px;
        display: inline-block;

        label{
            color: black;
            font-weight: bold;
            font-size: 12px;
            text-transform: uppercase;
            position: absolute;
            z-index: 2;
            left: 20px;
            top: 14px;
            padding: 0 10px;
            background-color: white;
            transition: transform .2s ease;
            border: none;
        }

        input{
            font-size: 14px;
            color: black;
            background-color: white;
            outline: none;
            border: 1px solid #4d4d4d;
            padding: 10px 20px;
            border-radius: 20px;
            position: relative;
            width: 300px;
        }

        input:focus + label,
        input:valid + label,
        &:hover input + label {
            color: #4d4d4d;
            transform: translateY(-20px);
            border: none;
        }
       
    }

    .two-input-wrapper{
        display: flex;
        flex-direction: column;
        width: 300px;
        gap: 5px;
        padding-bottom: 10px;

        span{
            color: black;
            font-size: 13px;
            font-weight: bold;
        }
        .Radios{
            display: flex;
            gap: 20px;
            
        .radioInput{
            label{
                display: flex;
                gap: 10px;
                font-size: 13px;
            }
           
        }
    }
    }



    .SettingsSelect{
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 300px;

        label{
            font-size: 13px;
            font-weight: bold;
        }

        .custom-select{
            width: 300px;
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 20px;
            background-color: #fff;
            color: #333;
            font-size: 14px;
        }
        .custom-select:hover {
            border-color: #666;
          }
          
          .custom-select:focus {
            outline: none;
            border-color: #007bff; /* Change to your desired focus color */
            box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
          }
    }
}
}


.settingsButton{
    display: flex;
    justify-content: center;
    align-items: center;

    @media(max-width: 767px){
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3%;
    }

    input{
       background-color: #02b159 !important;
       color: white;
       padding: 10px 40px;
       border-radius: 20px;
    }
   }

}