.MainProfile{
   display: flex;
   flex-direction: column;

   .Content{
    padding: 10px 20px;

    select{
        border: none;
        padding: 0px 20px;
        text-transform: uppercase;
    }
   }
   
    .ImageHeader{
       display: flex;
       flex-direction: row;
       gap: 2rem;
        img{
            height: 50%;
            width: 50%;
            flex: 60%;
            padding: 10px 20px;
        }

        .CompanyDesc{
            flex: 40%;
            display: flex;
            flex-direction: column;
            gap: 2rem;

            h1{
                text-align: center;
                color: #04304c;
            }

            .Company{
                display: flex;
                flex-direction: row;
                gap: 2rem;
                
                .Border{
                    border-left: 2px solid #02b159;
                }
                .Desc{
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    
                    span{
                        text-transform: uppercase;
                    }
                    a{
                        text-decoration: none;
                        text-transform: capitalize;
                        color: #02b159;
                    }
                }
            }
            
        }
    }

    .BodyCeo{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
       
        h1{
            text-align: center;
            width: 70%;
            color: #04304c;
        }
        p{
            width: 60%;
            display: block;
            margin-block-start: 1em;
            margin-block-end: 1em;
            margin-inline-start: 0px;
            margin-inline-end: 0px;
            font-weight: 400;
            font-size: 17px;
            color: #04304c;
        }
    }

    .Landingfooter{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 10px;
        background-color: #02b159;
        background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
        padding-top: 70px;
        padding-bottom: 70px;


        span{
            color: white;
            font-weight: bold;
        }
        button{
            color: white;
            background-color: transparent;
            border: 1px solid white;
            border-radius: 5px;
            padding: 10px 30px;

            a{
                color: white;
                text-decoration: none;
                font-size: 20px;
            } 
        }   
        
        button:hover{
            color: white;
            background-color: rgba(0, 0, 0, 0.753);
            border: 1px solid rgba(0, 0, 0, 0.753);
            padding: 10px 30px;
        }
     
        small{
            color: white;
            a{
                color: white;
                text-decoration: none;
                font-size: 16px;
            } 
        }
    }
}