.loginBody{
  margin-top: 0px;
  height: 100vh;
}
.partOne{
display: flex;
gap: 1rem;
justify-content: flex-end;
padding-top: 0px;
padding-right: 100px;
padding-left: 50px;
background: white;
color: black;
height: 10px; 

}

@media screen and (max-width:1000px){
  
  .partOne{
    display: flex;
    justify-content: space-between;
    justify-content: flex-end;
    padding-top: 15px;
    padding-right: 10px;
    padding-left: 10px;
    background: white;
    color: black;
    height: 130px; 
    
    }


  .partOneB{
    display: flex;
    }
  
}
.buttonlog{
color: black;
font-size: 14px;
}

.buttonlog:hover{
  color: green;
}


.partTwo{
  background: white;
  height: 60px;
}

.columnLog{ 

    position:absolute;
    top:-40px; 
    left: 130px;
    width:220px;
    height:220px;
  
} 

.login {
    display: flex;
    border: 1px grey;
    
  }

  .login .card {
    width: 100%;
    display: flex;
    background-color: white;
    min-height: 600px;
    overflow: hidden;
    
  }
  
  @media screen and (max-width:1000px){
    .one{
       display: none;   
    }
  
}