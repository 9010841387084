.seetask{

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    padding: 20px 50px;
    margin-bottom: 50px;
    margin-left: 50px;
    margin-right: 50px;
    margin-top: 30px;
    border: 1px solid grey;
    background-color: rgb(236, 233, 233);
    border-radius: 5px;
    width: auto;

    h3{
        color: black;
        text-transform: capitalize;
        font-weight: 700;
    }

    hr{
        color: black;
        height: 5px;
    }

    h4{
        font-weight: 700;
    }

    span{
        font-weight: 500;
        font-size: 18px;
    }
    
}