.StepThree{
    background-color: transparent;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin-bottom: 20px;

    .Contacts{
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        @media(max-width: 767px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
        }

        .ContactsForm{
            display: flex;
            flex-direction: column;
            gap: 10px;

            label{
                color: black;
            }

            input{
                border: 1px solid grey;
                width: 300px;
                padding: 5px 10px;
                border-radius: 5px;
            }
        }
    }

    .ReactQuill{
        margin-top: 20px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        @media(max-width: 767px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
        }

        label{
            color: black;
            font-weight: bold;
        }

        input{
            border: 1px solid grey;
            width: 300px;
            padding: 5px 10px;
            border-radius: 5px;
        }
    }

    .FileRef{
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media(max-width: 767px){
            margin-top: 60px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; 
        }
        label{
            color: black;
        }

        input{
            border: 1px solid grey;
            width: 300px;
            padding: 5px 10px;
            border-radius: 5px;
        }
    }
}