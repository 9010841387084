.StepFour{
    display: flex;
    flex-direction: column;
    justify-content: center;

    .DefenceNames{
        display: flex;
        justify-content: center;
        gap: 20px;
        label{
            font-weight: bold;
        }
        input{
            width: 400px;
        }
    }

    .perticularDetails{
        display: flex;
        flex-direction: column;
        gap: 0px;
        justify-content: center;

        label{
            font-weight: bold;
        }
        input{
            width: 400px;
        }
    }
}