.InvoiceMain{
    display: flex;
    width: 100%;
    margin: 30px;
   

.invoice{

    padding-top: 20px;
    border-radius: 5px;
    margin-bottom: 10px;
    font-family: sans-serif;
    width: 100%;
    max-width: 1024px;
    padding-left: 150px;
    padding-right: 150px;
   

    .invoicehead{
        display: flex;
        justify-content: space-between;
       
       
        .invoicelogo{
            background-color: black;
            color: white;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            display: flex;
            font-size: 20px;
            align-items: center;
            justify-content: center;
        }
      

    }
    .invoicebody1{
        padding-top: 20px;
        background-color: #02b159;
        background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
        border-radius: 5px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid gray;
        font-family: sans-serif;
        color: white;
        h5{
         text-align: center;
         color: white;
         font-weight: 600;
        }
        h4{
         color: white;
         font-weight: 600;
        }
     }
     .invoicebody2{
        background-color: rgb(29, 25, 25);
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        border: 1px solid gray;
        font-family: sans-serif;

        h5{
            color: black;
            text-align: center;
        }
        h4{
            color: black;
        }
        span{
            color: black;
        }
        .perticulars{
            padding: 5px ;

           h4{
            font-weight: 500;
           }
           p{
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: auto;
                overflow: hidden;
           }
        }
       
     }
     .invoicebody2{
        background-color: rgb(255, 255, 255);
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 5px;

        .summarytotal{
            display: flex;
            justify-content: space-between;
            padding: 1px;
            border-bottom: 1px solid rgb(212, 201, 201);
            
            h6{
                color: black;
                font-size: large;
                font-family: sans-serif;
            }
        }
     }
   
}
}