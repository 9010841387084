.MainPage{
    padding-left: 8%;
    padding-right: 8%;
    padding-top: 8%;
    padding-bottom: 8%;

    

    @media(max-width: 767px){
        padding-left: 3%;
        padding-right: 3%;
        margin-top: 12%;
        padding-bottom: 8%;
     
    }
}