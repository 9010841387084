.widget{
    display: flex;
    justify-content: space-between;
    flex: 1;
    padding: 10px;
    border-radius: 10px;
    height: auto;
    color: rgb(0, 255, 179);
    font-weight: bold;
    width: auto;
    filter: brightness(1.0);

    .left{
        display: flex;
        flex-direction: column;

        .title{
            font-weight: bold;
            font-size: 14px;
            color: white;
            font-weight: 400;
        }
        .counter{
            font-size: 28px;
            font-weight: 300;
        }
        .link{
            width: max-content;
            font-size: 12px;
            border-bottom: 1px solid transparent;
            color: white;
        }

       

       

    }
}