.MainNav{
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow: scroll;
    background-color: rgba(0, 153, 255, 0);
    border-radius: 5px;
    height: 80vh;
    width: auto;

    @media(max-width: 767px){
        width: auto;
      }

    .Views{
        display: flex;
        gap: 20px;
        cursor: pointer;
        padding: 10px;
        padding-left: 25px;
        
        .ViewsIcon{
            color: rgb(17, 43, 75);

            .IconView{
                font-size: 35px;
            }
        }
        .ViewsDetals{
            display: flex;
            flex-direction: column;
            gap: 3px;

            .MainDecs{
                color: black;
                font-weight: 600;
                font-size: 18px;
            }
            .PartDesc{
                color: grey;
                font-size: 15px;
                font-weight: 400;
            }
        }
    }
    .Views:hover{
        background-color: rgba(0, 153, 255, 0.164);
        border-radius: 10px;
    }
}