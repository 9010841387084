.Notices{
    padding-left: 10%;
    padding-right: 10%;
    border: 1px solid transparent;
    margin-top: 5%;

    .noticeHead{
        text-align: center;
        text-decoration: underline;
        text-transform: uppercase;
        padding-top: 15px;
        
        
        h4{
                text-align: center;
                text-decoration: underline;
                font-size: 16px; 
                font-weight: bold;
                text-transform: uppercase;
                font-family: Georgia, Times, "Times New Roman", serif;
                font-style: normal; 
                font-variant: normal;
        }
    }

    .NoticeParties{
        padding-top: 20px;
        padding-bottom: 20px;
        text-align: center;
        
        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 15px; 
            font-style: normal; 
            font-variant: normal;
        }
    }

    .noticeTitle{
        text-align: center;
        padding-top: 15px;
        
        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 18px; 
            font-style: normal; 
            font-variant: normal;
            text-decoration: underline;
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .DefenceFirmdetails{
        display: flex;
        flex-direction: column;
        padding-top: 20px;

       
       
        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 16px; 
            font-style: normal; 
            font-variant: normal;
            text-transform: uppercase;
        }

        }

    .noticebody{
            display: flex;
            flex-direction: column;
            gap: 1rem;
        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 16px; 
            font-style: normal; 
            font-variant: normal;
            padding-top: 15px;
        }
    }
    .documentDate{
        
        text-align: left;
        padding-top: 15px;

        span{
            
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 16px; 
            font-style: normal; 
            font-variant: normal;
            
        }
    }
    .firmdetails{
        display: flex;
        flex-direction: column;
        text-align: right;
        padding-top: 15px;

        img{
            float: right;
            width: 150px;
            height: 40px;
            
        }
       
        span{
            font-weight: bold;
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 16px; 
            font-style: normal; 
            font-variant: normal;
           
        }
    }
    .drawnby{
        display: flex;
        flex-direction: column;
        padding-top: 20px;

        img{
            width: 150px;
            height: 40px;
        }
       
        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 16px; 
            font-style: normal; 
            font-variant: normal;
        }
    }
}