.SideAnalytics{
    background-color: rgb(17, 43, 75);
    flex: 50%;
    border-radius: 5px;
    width: auto;


    @media(max-width: 767px){
        width: 100%;
      }
   

    .SideAnalyticsPartA{
        padding: 15px;
        color: white;
        padding-top:30px;
        padding-bottom: 30px;
        h5{
            text-align: center;
            font-weight: 400;
        }

        .TodayAnalysis{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            padding-top: 10px;

            .EachItem{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color:  white;
                span{
                    font-weight: 400;
                    font-size: 15px;
                }
            }
        }
    }
    .SideAnalyticsPartB{
        padding: 15px;
        color: white;
        border-radius: 5px;
        padding-bottom: 30px;
        h5{
            text-align: center;
            font-weight: 400;
        }

        .TodayAnalysis{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding-top: 10px;

            .EachItem{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color:  white;
                cursor: pointer;
                span{
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }
    }
    .SideAnalyticsPartC{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-bottom: 30px;

        h5{
            color: white;
            text-align: center;
            font-weight: 400;
        }
        .Bookings{
            padding-top: 30px;
            button{
                background-color:  rgb(39, 179, 137);
                color: white;
                font-weight: bold;
                padding: 10px 20px;
                border-radius: 10px;
                font-weight: 18px;
            }
        }
        .TodayAnalysis{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding-top: 10px;

            .EachItem{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                cursor: pointer;
                span{
                    font-weight: 400;
                    font-size: 15px;
                }
            }
        }
    }
}