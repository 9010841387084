.landingHome{

  
   .Contacts{
        display: flex;
        justify-content: space-between;
        background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #02b159, #02b159);
        padding: 10px 40px;
        .PartA{
            display: flex;
            flex-direction: row;
            gap: 50px;
            span{
                display: flex;
                gap: 10px;
                color: white;
            }
        }
        
        .PartB{
            display: flex;
            gap: 20px;
            cursor: pointer;

            .item{
                color: white;
            }
            .item:hover{
                color: rgba(255, 255, 255, 0.479);
            }
        }

        

   }
    .navA{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 15px;
        padding-right: 50PX;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.090);

        span{
            color: black;
            cursor: pointer;   
            font-weight: 500;
            padding: 5px 5px;
            font-size: 16px;

            button{
                color: white;
                background-color: rgba(0, 0, 0, 0.877);
                border: 1px solid black;
                padding: 3px 15px;
                border-radius: 5px;
             

                a{
                    color: white;
                    text-decoration: none;
                    font-weight: bold;
                    
                }
                a:hover{
                    color: white;
                    text-decoration: none;
                    font-weight: bold;
                   
                }
            }

            button:hover{
                padding: 3px 15px;
                border-radius: 5px;
                transition: 1s;
                box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
                

                a{ 
                    color: white;
                    text-decoration: none;
                    font-weight: bold;
                   
                }
                a:hover{
                    color: white;
                    text-decoration: none;
                    font-weight: bold;
                   
                }
            }
            a{
                color: black;
                text-decoration: none;
            } 
            a:hover{
                color: #02b159;
                text-decoration: underline;
                text-decoration-thickness: 2px;
            }
 
        }
       
        
    }

    .navB{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.090);
        
        
        .CompanyName{
           
            img{
                position:absolute;
                top:60px; 
                left:0px;
                width:220px;
                height:170px;
            } 
        }
        .Bnavigation{
            display: flex;
            gap: 15px;
            position: relative;

            span{
                color: black;
                cursor: pointer;   
                font-weight: 500;
                padding: 5px 5px;
                font-size: 16px;
                a{
                    color: black;
                    text-decoration: none;
                } 
                a:hover{ 
                    
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-decoration-color: #02b159;
                }

                button{
                    color: white;
                    background-color: rgba(0, 0, 0, 0.877);
                    border: 1px solid black;
                    padding: 3px 15px;
                    border-radius: 5px;
                   
                    a{
                        color: white;
                        text-decoration: none;
                        font-weight: 500;

                    }
                    a:hover{
                        color: white;
                        text-decoration: none;
                        font-weight: 500;
                    }
                }
                button:hover{
                    color: white;
                    transition: 1s;
                    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
                    
                }

                .LFHCare{
                    color: white;
                    background-color: #02b159;
                    padding: 3px 15px;
                    border-radius: 5px;
                    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
                   
                    a{
                        color: white;
                        text-decoration: none;
                        font-weight: 500;

                    }
                    a:hover{
                        color: white;
                        text-decoration: none;
                        font-weight: 500;
                    }
                }
                .LFHCare:hover{
                    background-color: rgba(0, 0, 0, 0.74);
                    color: white;
                    transition: 1s;
                    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
                    
                }

                select{
                    border: none;
                    outline: none;
                    color: black;
                    cursor: pointer;   
                    font-weight: 500;

                    option{
                        background-color: #02b159;
                    }
                }
                select:hover{
                    
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-decoration-color: #02b159;
                }
    
            }

        }
    }
}