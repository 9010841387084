.profile{
    margin-top: 30px;
    margin-left: 50px;
    margin-right: 50px;
    display: flex;
    gap: 20px;
    flex-direction: column;


    .ProfileName{
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: rgba(2, 2, 48, 0.842);
        height: 10vh;
        border-radius: 5px;
        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 25px; 
            font-style: normal; 
            font-variant: normal;
            font-weight: 700;
            color: white;
            text-transform: capitalize;
        }
    }
  
    .Performance{
        
        display: flex;
        flex-direction: column;

        .performanceHeaders{
            display: flex;
            justify-content: space-between;
            align-items: center;

            .item{
                display: flex;
                justify-content: space-between;
                flex-direction: column;
                padding: 10px 20px;
                box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.42);
                -webkit-box-shadow: 2px 4px 10px 1px rgba(0,0,0,0.42);
                border-radius: 10px;
                height: 110px;
                background-color: white;
                color: black;

                span{
                    font-family: Georgia, Times, "Times New Roman", serif;
                    font-size: 15px; 
                    font-style: normal; 
                    font-variant: normal;
                    font-weight: 500;
                }

                .AmountCounter{
                    span{
                        color: #02b159;
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
                .FileClosure{
                    span{
                        color: red;
                        font-weight: 600;
                        font-size: 20px;
                    }
                }
            }

        
        }
    }
}