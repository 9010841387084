.LFHCare{
    margin-top: 50px;
    

    .LFHMain{
        display: flex;
        flex-direction: column;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 10px;
        padding-bottom: 10px;
        gap: 15px;
        background-color: #02b159;
        background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #02b159, #02b159);
        color: white;

        .Care{
            display: flex;
            flex-direction: row;
            gap: 50px;

            .CareText{
                display: flex; 
                flex-direction: column;
                justify-content: center;
                flex: 50%;
                width: 100%;
                
            .FindaLawyer{
                display: flex;
                flex-direction: row;
                gap: 20px;
                padding-top: 15px;

                button{
                    color: white;
                    background-color: rgba(0, 0, 0, 0.877);
                    border: 1px solid black;
                    border-radius: 10px;
                    padding: 10px 20px;
                    font-weight: 600;
                }
            }
            }

            .CareImg{
                display: flex;
                flex: 50%;
                padding-left: 10%;
                img{
                    width: 100%;
                    height: auto;
                    border-radius: 10px;
                }
            }
        }

     
    }

       .LFHBody{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-top: 30px;
            padding-left: 15%;
            padding-right: 15%;

            h6{
                color: black;
                font-size: 17px;
                font-weight: normal;
            }

            h5{
                color: #0e643a;
            }
        }
}