.Messagebar{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 30%;
    padding: 10px 20px;
    background-color: #02b159;
    background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom right, #2f6f4e, #02b159);
    color: white;
    font-family: Georgia, Times, "Times New Roman", serif;
}


.Inboxbar{
    display: flex;
    justify-content: space-between;
    height: 50px;
    width: 820px;
    padding: 10px 20px;
    font-family: Georgia, Times, "Times New Roman", serif;
    background-color: #02b159;
    background-image: url(https://www.linode.com/wp-content/themes/linode-website-theme/images/Triangles-Background--Desktop.png),linear-gradient(to bottom left, #2f6f4e, #02b159);
    
}

.inboxBarText{
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 25px;
    font-family: Georgia, Times, "Times New Roman", serif;
   
}

.MailButton{
    background-color: #e6e6e6;
    border: none;
    border-radius: 20px;
    color: black;
    padding: 10px 20px;
    font-family: Georgia, Times, "Times New Roman", serif;
}


.MailButtonInbox{
    display: flex;
    gap: 10px;
    background-color: #e6e6e6;
    border: none;
    border-radius: 20px;
    color: black;
    padding: 10px 20px;
    font-family: Georgia, Times, "Times New Roman", serif;
}


.GoTocase{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e6e6e6;
    padding: 5px 10px;
    color: black;
    border-radius: 20px;
    font-family: Georgia, Times, "Times New Roman", serif;
}

.ButtonInbox{
    background-color: transparent;
    color: white;
    
}