*{
    box-sizing: border-box;
}

.clients {
    font-family: Helvetica;
    border-collapse: collapse;
    width: 100%;
    text-align: center;
    position: relative;
    margin-bottom: 50px;
  }
  
  .clients td, .clients th {
    border: 1px solid rgb(204, 201, 201);
    padding: 8px;
  }
  
  .clients tr:nth-child(even){background-color: #f2f2f2;}
  
  .clients tr:hover {background-color: rgb(240, 232, 232);}
  
  .th {
    position: sticky;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    color:#ddd;
    background-color: #cfb53b;
    color: black;
  }
  .clients thead {
    text-align: center;
    padding-left: 100px;
    background-color: whitesmoke;
    
  }

  .status{
    font-weight: bold;
    font-size: 0.75rem;
    color: white;
    background-color: black;
    border: 1px solid rgb(204, 201, 201);
    border-radius: 8px;
    padding: 3px 5px;
    display: inline-block;
  }

.link{
  text-align: right;
  text-decoration: none;
}
