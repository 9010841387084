.message{
        margin-left: 10px;
        border: none;
        border-radius: 10px;
        width: 100%;
        padding-left: 50px;
        padding-right: 50px;

        .messagebuttons{
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;

            button{
                color:white;
                background-color: #02b159;
                border-radius: 20px;
                padding: 7px 20px;
            }
        }

    .subjectline{
        display: flex;
        justify-content: space-between;
        padding-top: 0px;
        padding-bottom: 10px;
        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 16px; 
            font-style: normal; 
            font-variant: normal;
            font-weight: 600;
        }
       
    }

    .messagehead{
        display: flex;
        justify-content: space-between;

        .headeravatar{
            display: flex;
            flex-direction: column;
            gap: 5px;

            img{
                height: 70px;
                width: 70px;
                border: 1px solid black;
                border-radius: 50%;
            }
            span{
                font-family: Georgia, Times, "Times New Roman", serif;
                font-size: 15px; 
                font-style: normal; 
                font-variant: normal;
            }
        }

       
    }

    .messageBody{
        padding-top: 20PX;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 80px;

        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 15px; 
            font-style: normal; 
            font-variant: normal;
        }
    }

    .messageBottom{
        padding-top: 20PX;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;

        span{
            font-family: Georgia, Times, "Times New Roman", serif;
            font-size: 15px; 
            font-style: normal; 
            font-variant: normal;
            font-weight: bold;
        }
    }
}