.word-animation {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;

  
    span {
      display: inline-block;
      opacity: 0;
      transform: translateY(100%);
      animation: appear 0.3s forwards;
      font-size: 38px;
      color: #63a543;
      border-bottom: 4px solid orange;
      font-weight: bold;
  
      &.active {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
  
  @keyframes appear {
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  