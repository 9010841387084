.PartiesPage{
    .AddParty{
        button{
            background-color: #241842;
            color: white;
            padding: 10px 10px;
            border: none;
            outline: none;
            border-radius: 5px;
            font-weight: bold;
            font-size: 16px;
            font-style: normal;
        }
        .ModalBox{
            .BoxParty{
                .Parties{
                    h3{
                        color: red;
                    }
                }
            }
        }
    }
}