.notification{
    padding-top: 10px;
    background: white;
}
.ptag{
    color: black;
    font-size: 14px;
    text-align: left;
    font-weight: 400;
}

.paymentstatus{
    margin-top: 7px;
    font-weight: bold;
    font-size: 0.75rem;
    color: white;
    background-color: black;
    border-radius: 8px;
    padding: 3px 5px;
    display: inline-block;
}

.req{
    height: 100vh;
    overflow: scroll;
}