.header{
    width: 100%;
    background-color: transparent;
    margin-bottom: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;


    .Contacts{
        display: flex;
        justify-content: space-between;
        margin-bottom: 5px;
        transition: background-color 0.3s ease;
        margin-top: 20px;
        margin-bottom: 10px;
       
        padding-left: 100px;
        padding-right: 100px;
        height: 65px;
        border: none;
        box-shadow: none;
        @media(max-width: 767px){
            display: none;
            
        }

        .PartA{
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 50px;
            span{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                color: white;
            }
        }
        
        .PartB{
            display: flex;
            gap: 20px;
            cursor: pointer;

            .item{
                color: white;
            }
            .item:hover{
                color: rgba(255, 255, 255, 0.479);
            }
        }

        

   }

   .ContactsSmall{
    display: none;
    @media(max-width: 767px){
        display: flex;
        justify-content: center;
        
        padding: 10px 40px;
        gap: 20px;
        padding-left: 30px;
        padding-right: 30px;
        margin-bottom: 10px;

        button{
            padding: 10px 15px;
            border-radius: 5px;
            background-color: transparent;
            border: 1px solid grey;
           a{
            color: white;
           }

        }
        
    }

   }
    .navbar{
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        width: 100%;
        transition: background-color 0.3s ease;
        margin-bottom: 10px;
        height: 65px;
        border: none;
        box-shadow: none;

        &.scrolled {
            background-color: white;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
            color: black;
          }
          @media(max-width: 767px){
            transition: background-color 0.3s ease;

            &.scrolled {
                background-color: white;
                box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
                color: black;
              }
        }

        .logo{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-left: 2rem;
          
            
             img{
                position: absolute;
                top: -30px;
                left: -30px;
                width: 220px;
                height: 140px;
            }

        
            @media(max-width: 767px){
                padding-left: 0;
                
            }
        }
        .nav-items {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 20px;
            padding-right: 2rem;
            


           
            span{
                font-size: 14px;
                
                a{
                    color: white;    
                    text-transform: uppercase;
                    font-size: 14px;
                    letter-spacing: 1px;
                    font-weight: 400;
                    text-decoration: none;

                    &.scrolled {
                        color: black;
                      }

                      
            @media(max-width: 767px){
                color: black;
               
                &.scrolled {
                    color: black;
                  } 
            }
                }

                button{
                    padding: 10px 15px;
                    background-color: #63a543 !important;
                    border: none;
                    border-radius: 10px;
                    text-transform: capitalize;

                    a{
                        color: white;
                        font-weight: normal;
                        font-size: small;

                        
                    }
                }
            }


            @media(max-width: 767px){
                display: none;
              
                 &.active{
                display: flex;
                flex-direction: column;
                width: 100%;
                z-index: 1000;
                transition: background-color 0.3s ease;
                background-color: white;
                height: auto;
                margin: auto;
                padding: 2rem 0;
                top: 0;
                right: 0;
                left: -100%;
                transition: right 0.3s ease; 
                color: black;
                margin-top: 57px;
                border-top: 1px solid #02b159;

                &.scrolled {
                    background-color: white;
                    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1); 
                    color: black;
                  }
            }


            }
        }
        .hamburger {
            display: none;
            @media (max-width: 767px) {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 5px 5px;
              cursor: pointer;
              position: fixed;
              top: 0; 
              right: 0; 
              span {
                color: #02b159;
              }
            }
          }
    }
}